import { EPointInTimeType } from 'ts/commons/time/EPointInTimeType';
import type { TypedPointInTime } from 'ts/commons/time/TypedPointInTime';

/** Contains the possible tabs of the time picker. */
export enum ETimePickerType {
	BASELINE = 'Baseline',
	TIMESTAMP = 'Time',
	TIMESPAN = 'Timespan',
	REVISION = 'Revision',
	AMBIGUOUS_REVISION = 'Commit Selector',
	SYSTEM_VERSION = 'System Version'
}

/**
 * Determines the time picker tab based on the type of a {@link TypedPointInTime}.
 *
 * The conversion is necessary, because the time picker has more tab types ({@link ETimePickerType}) than time types
 * ({@link EPointInTimeType}). When a {@link TypedPointInTime} was already selected and the time picker is reopened, the
 * corresponding tab has to be displayed for the user.
 */
export function getDefaultTimePickerType(pointInTime: TypedPointInTime | null): ETimePickerType {
	if (pointInTime != null) {
		switch (pointInTime.type) {
			case EPointInTimeType.BASELINE:
				return ETimePickerType.BASELINE;
			case EPointInTimeType.TIMESPAN:
				return ETimePickerType.TIMESPAN;
			case EPointInTimeType.REVISION:
				return ETimePickerType.REVISION;
			case EPointInTimeType.SYSTEM_VERSION:
				return ETimePickerType.SYSTEM_VERSION;
			case EPointInTimeType.TIMESTAMP:
			default:
				return ETimePickerType.TIMESTAMP;
		}
	}
	return ETimePickerType.TIMESTAMP;
}
