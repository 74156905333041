import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useTeamscaleServiceClient } from 'ts/base/hooks/TeamscaleServiceClientHook';
import { ExtendedPerspectiveContext } from 'ts/data/ExtendedPerspectiveContext';

/**
 * Queries the perspective context. This should only be used outside of the perspective context. When instantiating your
 * component with ReactUtils you should use the already retrieved context with usePerspectiveContext.
 */
export function usePerspectiveContextQuery(
	refetchOnMount: boolean | 'always' | undefined = 'always',
	useErrorBoundary = true
): UseQueryResult<ExtendedPerspectiveContext> {
	const client = useTeamscaleServiceClient();
	return useQuery(
		['perspective-context'],
		async () => new ExtendedPerspectiveContext(await client.getPerspectiveContext()),
		{
			refetchOnMount,
			// Ensure that the query only breaks the view if it failed to return any data
			useErrorBoundary(error, query) {
				return useErrorBoundary && query.state.data === undefined;
			}
		}
	);
}
