import { Button, Grid, Icon } from 'semantic-ui-react';
import { sortObjectsByKey } from 'ts-closure-library/lib/array/array';
import type { Callback } from 'ts/base/Callback';
import { useTeamscaleServiceClient } from 'ts/base/hooks/TeamscaleServiceClientHook';
import { useBaselineMutations, useBaselines } from 'ts/base/services/BaselineServices';
import { usePerspectiveContextQuery } from 'ts/base/services/PerspectiveContext';
import { ArrayUtils } from 'ts/commons/ArrayUtils';
import { PermissionUtils } from 'ts/commons/permission/PermissionUtils';
import { useTimePickerContext } from 'ts/commons/time/components/TimePickerContext';
import {
	convertDefinedPointInTimeToOption,
	DefinedPointInTimeDropdown,
	useControlledSelectValue
} from 'ts/commons/time/components/TimePickerUtils';
import { EPointInTimeType } from 'ts/commons/time/EPointInTimeType';
import type { TypedPointInTime } from 'ts/commons/time/TypedPointInTime';
import { openAddBaselineDialog, openEditBaselineDialog } from 'ts/perspectives/findings/baselines/BaselineDialogUtils';
import type { ProjectSpecificBaselineInfo } from 'ts/perspectives/findings/baselines/ProjectSpecificBaselineInfo';

function extractPointInTime(value: string): TypedPointInTime | null {
	const { name, project } = JSON.parse(value);
	if (name == null || project == null) {
		return null;
	}
	return { type: EPointInTimeType.BASELINE, value: { name, project } };
}

function setInitialValue(defaultValue: TypedPointInTime | null): string | undefined {
	if (defaultValue != null && defaultValue.type === EPointInTimeType.BASELINE) {
		return JSON.stringify({
			name: defaultValue.value.name,
			project: defaultValue.value.project
		});
	}
	return undefined;
}

/** Props for BaselineEditButton. */
type BaselineEditButtonProps = {
	selectedBaseline: ProjectSpecificBaselineInfo | undefined;
	baselines: ProjectSpecificBaselineInfo[];
	editBaseline: Callback<[ProjectSpecificBaselineInfo, ProjectSpecificBaselineInfo]>;
};

/** Provides a button to be able to edit the currently selected baseline by opening an edit baseline dialog. */
function BaselineEditButton({ selectedBaseline, baselines, editBaseline }: BaselineEditButtonProps): JSX.Element {
	const client = useTeamscaleServiceClient();
	const { projects } = useTimePickerContext();
	return (
		<Button
			basic
			disabled={selectedBaseline == null}
			fluid
			icon={<Icon name="edit" />}
			content="Edit"
			data-testid="edit-baseline-button"
			onClick={() => {
				openEditBaselineDialog(client, projects!, baselines, selectedBaseline!, 0, editBaseline);
			}}
		/>
	);
}

function getSelectedBaseline(
	baselines: ProjectSpecificBaselineInfo[],
	selectedValue?: string
): ProjectSpecificBaselineInfo | undefined {
	if (selectedValue != null) {
		const { project, name } = JSON.parse(selectedValue);
		return baselines.find(baseline => baseline.project === project && baseline.name === name);
	}
	return undefined;
}

/** Props for BaselineSelector. */
type BaselineSelectorProps = {
	baselines: ProjectSpecificBaselineInfo[] | undefined;
	editBaseline: Callback<[ProjectSpecificBaselineInfo, ProjectSpecificBaselineInfo]>;
	selectedValue?: string;
	setSelectedValue: Callback<string>;
};

/** Provides a selector for the baseline array if it's not null or undefined. */
function BaselineSelector({
	baselines,
	editBaseline,
	selectedValue,
	setSelectedValue
}: BaselineSelectorProps): JSX.Element {
	if (ArrayUtils.isEmptyOrUndefined(baselines)) {
		return <Grid.Column>There are no baselines defined in the selected projects.</Grid.Column>;
	}

	sortObjectsByKey(baselines, 'timestamp');
	baselines.reverse();
	return (
		<>
			<Grid.Column>
				<DefinedPointInTimeDropdown
					testId="baseline-select"
					selectedValue={selectedValue}
					setSelectedValue={setSelectedValue}
					displayObjects={baselines}
					optionMapper={convertDefinedPointInTimeToOption}
				/>
			</Grid.Column>
			<Grid.Column width={4}>
				<BaselineEditButton
					selectedBaseline={getSelectedBaseline(baselines, selectedValue)}
					baselines={baselines}
					editBaseline={editBaseline}
				/>
			</Grid.Column>
		</>
	);
}

/** A component for selecting a baseline. */
export function BaselinePicker(): JSX.Element | null {
	const { projects } = useTimePickerContext();
	const client = useTeamscaleServiceClient();
	const { data: perspectiveContext } = usePerspectiveContextQuery(false, false);
	const { selectedValue, setSelectedValue } = useControlledSelectValue(
		'baseline',
		extractPointInTime,
		setInitialValue
	);
	const { baselines } = useBaselines(projects);
	const { createBaseline, editBaseline } = useBaselineMutations(projects, baseline =>
		// Ensure that the last added/edited baseline is selected
		setSelectedValue(convertDefinedPointInTimeToOption(baseline).value as string)
	);
	if (projects == null || baselines === undefined) {
		return null;
	}

	return (
		<Grid columns="equal">
			<Grid.Row stretched>
				<BaselineSelector
					baselines={baselines}
					editBaseline={editBaseline}
					selectedValue={selectedValue}
					setSelectedValue={setSelectedValue}
				/>
			</Grid.Row>
			{PermissionUtils.mayDefineBaselines(perspectiveContext!.userInfo.permissionSummary, projects) && (
				<Grid.Row>
					<Grid.Column>
						<Button
							id="add-baseline-button"
							basic
							fluid
							icon={<Icon name="add circle" />}
							content="Add baseline"
							data-testid="add-baseline"
							onClick={() => openAddBaselineDialog(client, projects, baselines, createBaseline, 0)}
						/>
					</Grid.Column>
				</Grid.Row>
			)}
		</Grid>
	);
}
