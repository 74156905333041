import type { FormEvent } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Form } from 'semantic-ui-react';
import * as asserts from 'ts-closure-library/lib/asserts/asserts';
import type { Callback } from 'ts/base/Callback';
import { useTeamscaleServiceClient } from 'ts/base/hooks/TeamscaleServiceClientHook';
import { DateUtils } from 'ts/commons/DateUtils';
import { useTimePickerContext } from 'ts/commons/time/components/TimePickerContext';
import { useInputWithDefault } from 'ts/commons/time/components/TimePickerUtils';
import { EPointInTimeType } from 'ts/commons/time/EPointInTimeType';
import type { TypedPointInTime } from 'ts/commons/time/TypedPointInTime';

function validateAndExtractPointInTime(
	timestamp: number | null,
	setTypedPointInTime: Callback<TypedPointInTime>
): Promise<string | undefined> {
	if (timestamp == null) {
		return Promise.resolve('No date was selected.');
	}
	setTypedPointInTime({ type: EPointInTimeType.TIMESTAMP, value: { timestamp } });
	return Promise.resolve(undefined);
}

function useSetInitialValueWithClient() {
	const client = useTeamscaleServiceClient();
	const { defaultValue } = useTimePickerContext();
	const { data: selectedTimestampFromDefinedPointInTime } = useQuery(
		['selectedTimestampFromDefinedPointInTime', defaultValue],
		() => {
			asserts.assert(defaultValue != null, 'Default value was not expected to be undefined');
			if (defaultValue.type === EPointInTimeType.BASELINE) {
				return client
					.getBaselineInfo(defaultValue.value.project, defaultValue.value.name)
					.then(baselineInfo => baselineInfo.timestamp);
			}
			if (defaultValue.type === EPointInTimeType.SYSTEM_VERSION) {
				return client
					.getSystemVersionInfo(defaultValue.value.project, defaultValue.value.name)
					.then(versionInfo => versionInfo.commit.timestamp);
			}
			asserts.fail('Default value was expected to have "baseline" or "system version" type');
		},
		{
			enabled:
				defaultValue?.type === EPointInTimeType.BASELINE ||
				defaultValue?.type === EPointInTimeType.SYSTEM_VERSION,
			useErrorBoundary: false // If the query failed (e.g. the baseline does not exist), defaults to current date
		}
	);
	return (defaultValue: TypedPointInTime | null) =>
		setInitialValue(defaultValue, selectedTimestampFromDefinedPointInTime);
}

function setInitialValue(
	defaultValue: TypedPointInTime | null,
	defaultTimestampFromDefinedPointInTime?: number
): number {
	if (defaultTimestampFromDefinedPointInTime != null) {
		return defaultTimestampFromDefinedPointInTime;
	}
	if (defaultValue != null) {
		if (defaultValue.type === EPointInTimeType.TIMESTAMP || defaultValue.type === EPointInTimeType.REVISION) {
			return defaultValue.value.timestamp;
		}
	}
	return Date.now();
}

/** Props for DateTimePicker. */
type DateTimePickerProps = {
	hideTimeBox: boolean;
};

/** A component for picking a date and optional time of the day. */
export function DateTimePicker({ hideTimeBox }: DateTimePickerProps): JSX.Element | null {
	const setInitialValue = useSetInitialValueWithClient();
	const { selectedValue: selectedValueAsTimestamp, setSelectedValue: setSelectedValueAsTimestamp } =
		useInputWithDefault('date', validateAndExtractPointInTime, setInitialValue);
	const selectedValueAsDate = new Date(selectedValueAsTimestamp ?? Date.now());
	return (
		<Form.Input
			data-testid="date-picker"
			label={hideTimeBox ? 'Date' : 'Date and Time'}
			type={hideTimeBox ? 'date' : 'datetime-local'}
			defaultValue={DateUtils.convertToDateInputISOString(selectedValueAsDate, !hideTimeBox)}
			onInput={(event: FormEvent<HTMLInputElement>) => {
				if (event.currentTarget.value === '') {
					// If the field is empty, the value is not updated
					return;
				}
				const date = new Date(event.currentTarget.value);
				setSelectedValueAsTimestamp(date.getTime());
			}}
		/>
	);
}
