import { useTimePickerContext } from 'ts/commons/time/components/TimePickerContext';
import {
	convertDefinedPointInTimeToOption,
	DefinedPointInTimeDropdown,
	useControlledSelectValue
} from 'ts/commons/time/components/TimePickerUtils';
import { EPointInTimeType } from 'ts/commons/time/EPointInTimeType';
import type { TypedPointInTime } from 'ts/commons/time/TypedPointInTime';

function extractPointInTime(value: string): TypedPointInTime | null {
	const { name, project } = JSON.parse(value);
	if (name == null || project == null) {
		return null;
	}
	return { type: EPointInTimeType.SYSTEM_VERSION, value: { name, project } };
}

function setInitialValue(defaultValue: TypedPointInTime | null): string | undefined {
	if (defaultValue != null && defaultValue.type === EPointInTimeType.SYSTEM_VERSION) {
		return JSON.stringify({
			name: defaultValue.value.name,
			project: defaultValue.value.project
		});
	}
	return undefined;
}

/** A component for picking a system version. */
export function SystemVersionPicker(): JSX.Element | null {
	const { systemVersions } = useTimePickerContext();
	const { selectedValue, setSelectedValue } = useControlledSelectValue(
		'systemVersion',
		extractPointInTime,
		setInitialValue
	);

	return (
		<DefinedPointInTimeDropdown
			testId="system-version-select"
			selectedValue={selectedValue}
			setSelectedValue={setSelectedValue}
			displayObjects={systemVersions}
			optionMapper={convertDefinedPointInTimeToOption}
		/>
	);
}
