import { getRandomString } from 'ts-closure-library/lib/string/string';
import { ReactUtils } from 'ts/base/ReactUtils';
import { SuspendingErrorBoundary } from 'ts/base/SuspendingErrorBoundary';
import { TimePickerDialog } from 'ts/commons/time/components/TimePickerDialog';
import type { ETimePickerType } from 'ts/commons/time/ETimePickerType';
import type { TypedPointInTime } from './TypedPointInTime';

/**
 * Renders a dialog that lets the user choose a date and time in the history. This may be specified by selecting date
 * and time or, if a project is given, specified by giving a revision or baseline from the project. For .NET projects, a
 * program version may be chosen.
 */
export class PointInTimePicker {
	/**
	 * Shows the time picker dialog.
	 *
	 * @param projects An array of projects from which to retrieve baselines or revisions for selection. This may be
	 *   null to indicate the selection of a project-independent date. In this case only a basic date/time picker will
	 *   be shown.
	 * @param disabledTabs Optional tabs that should be disabled.
	 * @param hideTimeBox If true, setting the time is hidden and defaults to 00:00.
	 * @param defaultValue The given value will be shown on startup of the dialog if it is set to a valid value.
	 * @param dialogTitle The dialog title to use.
	 */
	public static showDialog(
		projects: string[] | null,
		disabledTabs: ETimePickerType[] = [],
		hideTimeBox = false,
		defaultValue: TypedPointInTime | null = null,
		dialogTitle = 'Timetravel to...'
	): Promise<TypedPointInTime> {
		return new Promise((resolve, reject) => {
			// The ID is used to store the state of this time picker instance
			const timePickerId = getRandomString();
			const wrapper = ReactUtils.appendStatic(
				<SuspendingErrorBoundary
					errorFallback={({ error }) => {
						reject(error);
						return null;
					}}
				>
					<TimePickerDialog
						dialogTitle={dialogTitle}
						onChange={resolve}
						onHide={() => PointInTimePicker.disposeContainer(timePickerId, wrapper)}
						disabledTabs={disabledTabs}
						hideTimeBox={hideTimeBox}
						defaultValue={defaultValue}
						id={timePickerId}
						projects={projects}
					/>
				</SuspendingErrorBoundary>,
				document.body
			);
		});
	}

	private static disposeContainer(timePickerId: string, container: Element): void {
		ReactUtils.unmount(container);
		container.parentNode?.removeChild(container);
		Object.keys(localStorage)
			.filter(x => x.startsWith('timepicker-' + timePickerId))
			.forEach(x => localStorage.removeItem(x));
	}
}
