import type { ReactNode } from 'react';
import { useMemo } from 'react';
import type { FieldValues } from 'react-hook-form';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import type { UseFormSetError } from 'react-hook-form/dist/types/form';
import { Form, Message, Tab } from 'semantic-ui-react';
import type { Callback } from 'ts/base/Callback';
import { ArrayUtils } from 'ts/commons/ArrayUtils';
import type { ActionButtonProps } from 'ts/commons/dialog/SaveCancelModal';
import { SaveCancelModal } from 'ts/commons/dialog/SaveCancelModal';
import { AmbiguousRevisionPicker } from 'ts/commons/time/components/AmbiguousRevisionPicker';
import { BaselinePicker } from 'ts/commons/time/components/BaselinePicker';
import { DateTimePicker } from 'ts/commons/time/components/DateTimePicker';
import { RevisionPicker } from 'ts/commons/time/components/RevisionPicker';
import { SystemVersionPicker } from 'ts/commons/time/components/SystemVersionPicker';
import { TimePickerContextProvider, useTimePickerContext } from 'ts/commons/time/components/TimePickerContext';
import { TimespanPicker } from 'ts/commons/time/components/TimespanPicker';
import { EPointInTimeType } from 'ts/commons/time/EPointInTimeType';
import { ETimePickerType } from 'ts/commons/time/ETimePickerType';
import { TimeUtils } from 'ts/commons/time/TimeUtils';
import type { TypedPointInTime } from 'ts/commons/time/TypedPointInTime';

/** Props for FormWithErrorMessages. */
type FormWithErrorMessagesProps = {
	children: JSX.Element;
};

/** Provides a form that displays the error messages in a message. Must be nested within a FormProvider. */
function FormWithErrorMessages({ children }: FormWithErrorMessagesProps): JSX.Element {
	const {
		formState: { errors }
	} = useFormContext();
	return (
		<Form>
			{children}
			{Object.keys(errors).length > 0 ? (
				<Message negative>
					{Object.keys(errors).map(key => {
						return <div key={key}>{errors[key]!.message}</div>;
					})}
				</Message>
			) : null}
		</Form>
	);
}

/** Pane type used for the {@link Tab}. */
type TimePickerTab = {
	menuItem: ETimePickerType;
	render: () => ReactNode;
};

/** Determines which tabs must be rendered and returns them as an array of panes for a {@link Tab}. */
function useTimePickerTabs(hideTimeBox: boolean, disabledTabs: ETimePickerType[]): TimePickerTab[] {
	const { projects, systemVersions, ambiguousRevision } = useTimePickerContext();
	return useMemo(() => {
		const components = [<DateTimePicker key={ETimePickerType.TIMESTAMP} hideTimeBox={hideTimeBox} />];
		if (!ArrayUtils.isEmptyOrUndefined(projects)) {
			components.push(<RevisionPicker key={ETimePickerType.REVISION} />);
			if (ambiguousRevision != null) {
				components.push(<AmbiguousRevisionPicker key={ETimePickerType.AMBIGUOUS_REVISION} />);
			}
			components.push(<BaselinePicker key={ETimePickerType.BASELINE} />);
			if (!ArrayUtils.isEmptyOrUndefined(systemVersions)) {
				components.push(<SystemVersionPicker key={ETimePickerType.SYSTEM_VERSION} />);
			}
		}
		components.push(<TimespanPicker key={ETimePickerType.TIMESPAN} />);
		return components
			.filter(component => !disabledTabs.includes(component.key as ETimePickerType))
			.map(component => {
				return {
					menuItem: component.key as ETimePickerType,
					render() {
						return (
							<Tab.Pane key={component.key} className="tab-content">
								<FormWithErrorMessages>{component}</FormWithErrorMessages>
							</Tab.Pane>
						);
					}
				};
			});
	}, [hideTimeBox, disabledTabs, projects, systemVersions, ambiguousRevision]);
}

/** Determines which tab index must be selected as active. */
function useActiveTabIndex(panes: TimePickerTab[]): number {
	const { activeTabKey } = useTimePickerContext();
	let activeTabIndex;
	if (activeTabKey != null) {
		activeTabIndex = Math.max(
			0,
			panes.findIndex(pane => pane.menuItem === activeTabKey)
		);
	} else {
		activeTabIndex = 0;
	}

	return activeTabIndex;
}

/** Props for TimePickerDialog. */
type TimePickerDialogProps = {
	/** An ID that can be used to store the state of one instance of the time picker dialog. */
	id: string;
	/**
	 * An array of projects from which to retrieve baselines or revisions for selection. This may be null to indicate
	 * the selection of a project-independent date. In this case only a basic date/time picker will be shown.
	 */
	projects: string[] | null;
	/** Optional tabs that should be disabled. */
	disabledTabs: ETimePickerType[];
	/** If true, setting the time is hidden and defaults to 00:00. */
	hideTimeBox: boolean;
	/** The given value will be shown on startup of the dialog if it is set to a valid value. */
	defaultValue: TypedPointInTime | null;
	/** The dialog title to use. */
	dialogTitle: string;
	/** A function that is called if setting the value is successful. */
	onChange: Callback<TypedPointInTime>;
	/** A function that is called when the dialog is closed. */
	onHide: Callback<void>;
	/** JSX Element which can open the timepicker dialog */
	trigger?: JSX.Element;
};

/** Provides a dialog for picking the time. */
export function TimePickerDialog(props: TimePickerDialogProps): JSX.Element {
	const { onChange, id, projects, defaultValue } = props;
	const formMethods = useForm({ mode: 'onSubmit', reValidateMode: 'onSubmit' });
	return (
		<FormProvider {...formMethods}>
			<TimePickerContextProvider onChange={onChange} id={id} projects={projects} defaultValue={defaultValue}>
				<TimePickerDialogInContext {...props} />
			</TimePickerContextProvider>
		</FormProvider>
	);
}

function useTimePickerActionButtons(disabledTabs: ETimePickerType[]): ActionButtonProps[] {
	const { handleSubmit, setError, clearErrors } = useFormContext();
	const { setTypedPointInTime, defaultValue } = useTimePickerContext();

	const buttons: ActionButtonProps[] = [
		{
			primary: true,
			content: 'OK',
			'data-testid': 'okButton',
			onClick: () => {
				clearErrors();
				// If everything was valid, the dialog can be closed, otherwise the error is handled by the FormWithErrorMessages
				return handleSubmit(
					() => Promise.resolve(),
					error => Promise.reject(error)
				)();
			}
		},
		{
			content: 'Cancel',
			'data-testid': 'cancelButton'
		}
	];
	if (!disabledTabs.includes(ETimePickerType.TIMESPAN) || !disabledTabs.includes(ETimePickerType.TIMESTAMP)) {
		buttons.push({
			content: 'Now',
			'data-testid': 'nowButton',
			onClick: () => {
				clearErrors();
				return onClickNowButton(
					setError,
					disabledTabs,
					defaultValue ?? {
						type: EPointInTimeType.TIMESTAMP,
						value: { timestamp: new Date().getTime() }
					},
					setTypedPointInTime
				);
			}
		});
	}
	return buttons;
}

/** Provides a dialog for picking the time. Must be nested within a FormProvider and a TimePickerContextProvider. */
function TimePickerDialogInContext({
	hideTimeBox,
	dialogTitle,
	disabledTabs,
	onHide,
	trigger
}: TimePickerDialogProps): JSX.Element {
	const { reset } = useFormContext();
	const { activeTabKey, setActiveTabKey } = useTimePickerContext();
	const panes = useTimePickerTabs(hideTimeBox, disabledTabs);
	const activeTabIndex = useActiveTabIndex(panes);
	const timePickerActionButtons = useTimePickerActionButtons(disabledTabs);

	return (
		<SaveCancelModal
			dimmer
			size="tiny"
			onHide={onHide}
			header={dialogTitle}
			actionButtonProps={timePickerActionButtons}
			trigger={trigger}
			id="time-picker-modal"
		>
			<div data-testid="time-revision-picker-content">
				<Tab
					onTabChange={(event, { panes, activeIndex }) => {
						if (
							panes != null &&
							activeIndex != null &&
							typeof activeIndex === 'number' &&
							activeIndex < panes.length &&
							activeTabKey !== panes[activeIndex]!.menuItem
						) {
							setActiveTabKey(panes[activeIndex]!.menuItem);
							reset();
						}
					}}
					menu={{ secondary: true, pointing: true }}
					panes={panes}
					activeIndex={activeTabIndex}
				/>
			</div>
		</SaveCancelModal>
	);
}

/** Handles the onClick event of the "Now" button. */
function onClickNowButton(
	setError: UseFormSetError<FieldValues>,
	disabledTabs: ETimePickerType[],
	defaultValue: TypedPointInTime | null,
	setTypedPointInTime: Callback<TypedPointInTime>
): Promise<void> {
	if (!disabledTabs.includes(ETimePickerType.TIMESPAN)) {
		setTypedPointInTime(TimeUtils.fullHistory());
		return Promise.resolve();
	}
	if (!disabledTabs.includes(ETimePickerType.TIMESTAMP)) {
		setTypedPointInTime(TimeUtils.timestamp(new Date().getTime()));
		return Promise.resolve();
	}
	setError('date', { message: 'Cannot set "Now" value when timespan and timestamp tabs are hidden' });
	return Promise.reject();
}
