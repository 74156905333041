import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { Callback } from 'ts/base/Callback';
import { useTeamscaleServiceClient } from 'ts/base/hooks/TeamscaleServiceClientHook';
import { useProjectId } from 'ts/base/hooks/UseProject';
import { ArrayUtils } from 'ts/commons/ArrayUtils';
import type { ProjectSpecificBaselineInfo } from 'ts/perspectives/findings/baselines/ProjectSpecificBaselineInfo';

/** Provides mutation functions to add, edit and delete baselines of the specified projects. */
export function useBaselineMutations(
	projects: string[] | null,
	onChange?: Callback<ProjectSpecificBaselineInfo>
): {
	createBaseline: Callback<ProjectSpecificBaselineInfo>;
	editBaseline: Callback<[ProjectSpecificBaselineInfo, ProjectSpecificBaselineInfo]>;
	deleteBaseline: Callback<ProjectSpecificBaselineInfo>;
} {
	const client = useTeamscaleServiceClient();
	const queryClient = useQueryClient();
	// All baseline queries have to be invalidated that refer to any of the given projects
	const invalidateBaselines = () =>
		queryClient.invalidateQueries({
			predicate: query =>
				query.queryKey[0] === 'baselines' &&
				(projects?.some(project => (query.queryKey[1] as string[] | undefined)?.includes(project)) ?? false)
		});
	const { mutate: editBaseline } = useMutation(
		([oldBaseline, newBaseline]: [ProjectSpecificBaselineInfo, ProjectSpecificBaselineInfo]) => {
			return client
				.setBaseline(newBaseline.project, newBaseline, oldBaseline.name)
				.then(() => onChange?.(newBaseline));
		},
		{ onSuccess: invalidateBaselines }
	);
	const { mutate: deleteBaseline } = useMutation(
		(baseline: ProjectSpecificBaselineInfo) => client.deleteBaseline(baseline.project, baseline.name),
		{ onSuccess: invalidateBaselines }
	);
	const { mutate: createBaseline } = useMutation(
		(baseline: ProjectSpecificBaselineInfo) =>
			client.setBaseline(baseline.project, baseline).then(() => onChange?.(baseline)),
		{
			onSuccess: invalidateBaselines
		}
	);
	return { createBaseline, editBaseline, deleteBaseline };
}

/** Provides a list of all baselines of the specified projects. */
export function useBaselines(projects: string[] | null): {
	baselines: ProjectSpecificBaselineInfo[] | undefined;
} {
	const client = useTeamscaleServiceClient();
	const { data } = useQuery(
		['baselines', projects],
		() =>
			client.getAllBaselinesByProjects(projects!).then(baselinesByProjects => {
				return Object.keys(baselinesByProjects).flatMap(project => {
					return baselinesByProjects[project]!.map(baselineInfo => {
						return {
							...baselineInfo,
							project
						};
					});
				});
			}),
		{ enabled: !ArrayUtils.isEmptyOrUndefined(projects) }
	);
	return { baselines: data };
}

/**
 * Returns the timestamp of the first commit that happened in the project to ensure baselines are only created after
 * that point in time. To avoid unnecessarily blocking the first render the hook returns 0 while the data is loading.
 */
export function useFirstRepositoryTimestamp(): number {
	const projectId = useProjectId();
	const client = useTeamscaleServiceClient();
	const { data } = useQuery(['first-commit', projectId], () => client.getSimpleRepositorySummary(projectId), {
		suspense: false
	});
	return data?.firstCommit ?? 0;
}
