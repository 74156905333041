import type { TeamscaleServiceClient } from 'ts/base/client/TeamscaleServiceClient';
import { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import { DateUtils } from './../DateUtils';
import { PromiseReject } from './../PromiseReject';
import type { DefinedPointInTime } from './DefinedPointInTime';
import { EPointInTimeType } from './EPointInTimeType';
import type { Revision } from './Revision';
import type { TimeSpan } from './TimeSpan';
import type { Timestamp } from './Timestamp';
import { TimeUtils } from './TimeUtils';
import type { TypedPointInTime } from './TypedPointInTime';

/** Time context for resolving commits and timestamps relative to the current time or an optional time travel commit. */
export class TimeContext {
	/** Map for resolvers of timestamps for different kinds of points in time. */
	public resolverMap = {
		[EPointInTimeType.BASELINE]: (baseline: DefinedPointInTime): Promise<number | null> =>
			this.getTimestampForBaseline(baseline),
		[EPointInTimeType.REVISION]: (revisionPointInTime: Revision): Promise<number | null> =>
			this.getTimestampForRevision(revisionPointInTime),
		[EPointInTimeType.SYSTEM_VERSION]: (systemVersion: DefinedPointInTime): Promise<number | null> =>
			this.getTimestampForSystemVersion(systemVersion),
		[EPointInTimeType.TIMESTAMP]: (dateTime: Timestamp): Promise<number | null> =>
			this.getTimestampForDateTime(dateTime),
		[EPointInTimeType.TIMESPAN]: (timeSpan: TimeSpan, baselineTimestamp: number): Promise<number | null> =>
			this.getTimestampForTimeSpan(timeSpan, baselineTimestamp)
	} as const;

	public constructor(
		private readonly client: TeamscaleServiceClient,
		public timeTravelCommit: UnresolvedCommitDescriptor | null = null
	) {
		// Nothing to do here
	}

	/** Resolves a commit relative within the given time context. */
	public resolveCommit(pointInTime: TypedPointInTime): Promise<UnresolvedCommitDescriptor>;
	public resolveCommit(pointInTime: TypedPointInTime | null): Promise<UnresolvedCommitDescriptor | null>;
	public resolveCommit(pointInTime: TypedPointInTime | null): Promise<UnresolvedCommitDescriptor | null> {
		return this.resolveCommitRelative(pointInTime, this.getTimeTravelCommit());
	}

	/** Ensures that a end commit is not in the future of the timetravel commit. */
	public ensureEndCommitNotAfterTimetravel(commit: UnresolvedCommitDescriptor): Promise<UnresolvedCommitDescriptor> {
		if (this.isBeforeTimeTravel(commit)) {
			return Promise.resolve(commit);
		}
		return Promise.resolve(
			UnresolvedCommitDescriptor.createCommitFromTimestamp(this.getTimeTravelCommit()?.timestamp ?? null, commit)
		);
	}

	/** Resolves the commit for a point in time relative to the time of the given baseline commit. */
	public resolveCommitRelative(
		pointInTime: TypedPointInTime | null,
		baselineCommit: UnresolvedCommitDescriptor | null
	): Promise<UnresolvedCommitDescriptor | null> {
		if (pointInTime === null) {
			return Promise.resolve(null);
		}
		return this.resolveTimestampAgainstBaseLine(pointInTime, baselineCommit).then(timestamp => {
			if (timestamp === null) {
				return baselineCommit ?? new UnresolvedCommitDescriptor();
			}
			return UnresolvedCommitDescriptor.createCommitFromTimestamp(timestamp, baselineCommit);
		});
	}

	/** Resolves the timestamp for a point in time relative the current time context. */
	public resolveToTimestamp(pointInTime: TypedPointInTime | null): Promise<number | null> {
		if (pointInTime === null) {
			return Promise.resolve(null);
		}
		return this.resolveTimestampAgainstBaseLine(pointInTime, this.getTimeTravelCommit());
	}

	/** Returns the configured time travel commit. */
	public getTimeTravelCommit(): UnresolvedCommitDescriptor | null {
		return this.timeTravelCommit;
	}

	/**
	 * Returns true if a commit occurred before the current time travel time.
	 *
	 * @param commit The commit to check.
	 */
	public isBeforeTimeTravel(commit: UnresolvedCommitDescriptor | null): boolean {
		if (this.getTimeTravelCommit() === null || (commit !== null && commit.getTimestamp() === null)) {
			return true;
		}
		return UnresolvedCommitDescriptor.firstCommitEarlierThanSecond(commit, this.getTimeTravelCommit());
	}

	/** Resolves the timestamp for a point in time relative the given baseline commit time. */
	private resolveTimestampAgainstBaseLine(
		pointInTime: TypedPointInTime,
		baselineCommit: UnresolvedCommitDescriptor | null
	): Promise<number | null> {
		let baselineTimestamp = null;
		if (baselineCommit !== null) {
			baselineTimestamp = baselineCommit.getTimestamp();
		}
		if (!TimeUtils.isTrend(pointInTime)) {
			return Promise.resolve(baselineTimestamp);
		}
		// @ts-ignore
		return this.resolverMap[pointInTime.type.toUpperCase()]!(pointInTime.value, baselineTimestamp);
	}

	/** Timestamp resolver function for date times. */
	public getTimestampForDateTime(dateTime: Timestamp): Promise<number | null> {
		return Promise.resolve(dateTime.timestamp);
	}

	/** Timestamp resolver function for timespans. */
	public getTimestampForTimeSpan(timespan: TimeSpan, baselineTimestamp?: number | null): Promise<number | null> {
		return Promise.resolve(DateUtils.getTimestampForDays(timespan.days, baselineTimestamp));
	}

	/** Timestamp resolver function for revisions. */
	public getTimestampForRevision(revisionPointInTime: Revision): Promise<number | null> {
		return Promise.resolve(revisionPointInTime.timestamp);
	}

	/** Timestamp resolver function for baselines. */
	public getTimestampForBaseline(baseline: DefinedPointInTime): Promise<number> {
		return this.client
			.getBaselineInfo(baseline.project, baseline.name)
			.then(baselineInfo => baselineInfo.timestamp)
			.catch(error => {
				throw new PromiseReject(error.errorSummary);
			});
	}

	/** Timestamp resolver function for system versions. */
	public getTimestampForSystemVersion(systemVersion: DefinedPointInTime): Promise<number> {
		return this.client
			.getSystemVersionInfo(systemVersion.project, systemVersion.name)
			.then(versionInfo => versionInfo.commit.timestamp)
			.catch(() => {
				throw TimeContext.createPromiseReject('System version', systemVersion);
			});
	}

	/** Ensures that a null commit is replaced with a HEAD commit on the default branch. */
	public static ensureEndTimestamp(commit: UnresolvedCommitDescriptor | null): UnresolvedCommitDescriptor {
		if (commit == null) {
			return new UnresolvedCommitDescriptor(null, null);
		}
		return commit;
	}

	/** Ensures that a null commit is replaced with a start commit containing a timestamp of 1. */
	public static ensureStartTimestamp(commit: UnresolvedCommitDescriptor | null): UnresolvedCommitDescriptor {
		if (commit == null) {
			return UnresolvedCommitDescriptor.createCommitFromTimestamp(1, commit);
		}
		return commit;
	}

	/** Creates a promise reject for a defined point in time. */
	public static createPromiseReject(
		readablePointInTimeType: string,
		definedPointInTime: DefinedPointInTime
	): PromiseReject {
		return new PromiseReject(
			readablePointInTimeType +
				' "' +
				definedPointInTime.name +
				'" not found for project "' +
				definedPointInTime.project +
				'".'
		);
	}
}
