import * as FindingsPerspectiveTemplate from 'soy/perspectives/findings/FindingsPerspectiveTemplate.soy.generated';
import * as forms from 'ts-closure-library/lib/dom/forms';
import type { BrowserEvent } from 'ts-closure-library/lib/events/browserevent';
import * as events from 'ts-closure-library/lib/events/eventhandler';
import { EventType } from 'ts-closure-library/lib/events/eventtype';
import type { Dialog } from 'ts-closure-library/lib/ui/dialog';
import { ButtonSet, DefaultButtons, EventType as DialogEventType } from 'ts-closure-library/lib/ui/dialog';
import type { Callback } from 'ts/base/Callback';
import type { TeamscaleServiceClient } from 'ts/base/client/TeamscaleServiceClient';
import { DateUtils } from 'ts/commons/DateUtils';
import { ConfirmActionWithString } from 'ts/commons/dialog/ConfirmActionWithString';
import { StringUtils } from 'ts/commons/StringUtils';
import { EPointInTimeType } from 'ts/commons/time/EPointInTimeType';
import { ETimePickerType } from 'ts/commons/time/ETimePickerType';
import { PointInTimePicker } from 'ts/commons/time/PointInTimePicker';
import { RevisionFormatter } from 'ts/commons/time/RevisionFormatter';
import { TimeContext } from 'ts/commons/time/TimeContext';
import { TimeUtils } from 'ts/commons/time/TimeUtils';
import { tsdom } from 'ts/commons/tsdom';
import { UIUtils } from 'ts/commons/UIUtils';
import { Validator } from 'ts/commons/Validator';
import type { ProjectSpecificBaselineInfo } from 'ts/perspectives/findings/baselines/ProjectSpecificBaselineInfo';
import type { BaselineInfo } from 'typedefs/BaselineInfo';

const BASELINE_NAME_ELEMENT_ID = 'name-text';

/**
 * Deletes a baseline.
 *
 * @param baseline The baseline to delete.
 * @param deletionConfirmedCallback Called when the user confirmed to delete the baseline with the baseline to be
 *   deleted.
 */
export function confirmDeleteBaseline(
	baseline: ProjectSpecificBaselineInfo,
	deletionConfirmedCallback: Callback<ProjectSpecificBaselineInfo>
): void {
	new ConfirmActionWithString(
		"Really delete baseline '" + baseline.name + "'?",
		() => deletionConfirmedCallback(baseline),
		null,
		'Delete baseline'
	);
}

/** Edits the given baseline by opening a dialog. */
export function openEditBaselineDialog(
	client: TeamscaleServiceClient,
	projects: string[],
	allBaselines: BaselineInfo[],
	oldBaseline: ProjectSpecificBaselineInfo,
	timestampOfFirstCommit: number,
	editBaseline: Callback<[oldBaseline: ProjectSpecificBaselineInfo, newBaseline: ProjectSpecificBaselineInfo]>
): void {
	openBaselineEditDialog(
		client,
		projects,
		allBaselines,
		oldBaseline,
		newBaseline => editBaseline([oldBaseline, newBaseline]),
		timestampOfFirstCommit,
		oldBaseline.name
	);
}

/** Adds a new baseline by opening a dialog */
export function openAddBaselineDialog(
	client: TeamscaleServiceClient,
	projects: string[],
	allBaselines: BaselineInfo[],
	createBaseline: Callback<ProjectSpecificBaselineInfo>,
	timestampOfFirstCommit: number
): void {
	const now = new Date().getTime();
	openBaselineEditDialog(
		client,
		projects,
		allBaselines,
		{
			project: projects[0]!,
			name: '',
			description: '',
			timestamp: now,
			baselineRevision: ''
		},
		createBaseline,
		timestampOfFirstCommit
	);
}

/**
 * Opens a dialog for editing a baseline.
 *
 * @param baseline The baseline to edit
 * @param callback The callback that will be called with the edited data when the editing finished successfully.
 * @param allowedOverwrite An optional name of a baseline the user may overwrite.
 */
function openBaselineEditDialog(
	client: TeamscaleServiceClient,
	projects: string[],
	allBaselines: BaselineInfo[],
	baseline: ProjectSpecificBaselineInfo,
	callback: Callback<ProjectSpecificBaselineInfo>,
	timestampOfFirstCommit: number,
	allowedOverwrite?: string
): void {
	const dialog = setupDialog(projects, baseline);

	events.listen(
		document.getElementById('choose-date'),
		EventType.CLICK,
		UIUtils.preventDefaultEventAction(() => promptChooseDate(client, projects, baseline)),
		true
	);
	const existingBaselineNames = allBaselines.map(existingBaseline => existingBaseline.name);
	events.listen(dialog, DialogEventType.SELECT, event =>
		onDialogClosed(
			baseline,
			existingBaselineNames,
			dialog,
			allowedOverwrite || null,
			callback,
			timestampOfFirstCommit,
			event
		)
	);
	tsdom.getHtmlInputElementById(BASELINE_NAME_ELEMENT_ID).focus();
}

function setupDialog(projects: string[], baseline: ProjectSpecificBaselineInfo): Dialog {
	const dialog = UIUtils.createDialogWithContent(
		StringUtils.isEmptyOrWhitespace(baseline.name) ? 'Add baseline' : 'Edit baseline',
		FindingsPerspectiveTemplate.baselineEditDialog,
		{
			baseline: {
				...baseline,
				formattedTimestamp: DateUtils.formatTimestamp(baseline.timestamp)
			},
			projects
		}
	);

	dialog.setButtonSet(ButtonSet.createOkCancel());
	dialog.setDisposeOnHide(true);
	dialog.setVisible(true);
	return dialog;
}

/**
 * Called when the edit dialog is closed.
 *
 * @param baseline The baseline to edit
 * @param existingBaselineNames The names of all existing baselines.
 * @param dialog The edit dialog.
 * @param baselineToOverwrite The name of a baseline that may be overwritten or <code>null</code> if no baseline should
 *   be overwritten.
 * @param callback The callback that will be called with the edited data when the editing finished successfully.
 * @param e The event that triggered this listener.
 */
function onDialogClosed(
	baseline: ProjectSpecificBaselineInfo,
	existingBaselineNames: string[],
	dialog: Dialog,
	baselineToOverwrite: string | null,
	callback: Callback<ProjectSpecificBaselineInfo>,
	timestampOfFirstCommit: number,
	e: BrowserEvent
): void {
	if (e.key !== DefaultButtons.OK.key) {
		return;
	}
	const project = forms.getValue(document.getElementById('project'))!.toString();
	const name = forms.getValue(document.getElementById(BASELINE_NAME_ELEMENT_ID))!.toString();
	const description = forms.getValue(document.getElementById('description-text'))!.toString();
	const timestamp = baseline.timestamp;
	const baselineRevision = baseline.baselineRevision;
	const validator = new Validator(dialog.getContentElement());
	validator.checkNotEmpty(name, 'Name');
	if (name !== baselineToOverwrite && existingBaselineNames.includes(name)) {
		validator.appendError('A baseline named "' + name + '" already exists');
	}
	if (timestamp < timestampOfFirstCommit) {
		validator.appendError('Please select a date after the first');
		validator.appendError('commit on ' + DateUtils.formatTimestamp(timestampOfFirstCommit) + '.');
	}
	if (validator.hasErrors()) {
		// Prevent dialog from closing
		e.preventDefault();
	} else {
		callback({ name, description, timestamp, baselineRevision, project });
	}
}

/** Opens a time/revision picker for choosing the timestamp for the baseline. */
async function promptChooseDate(
	client: TeamscaleServiceClient,
	projects: string[],
	baseline: ProjectSpecificBaselineInfo
): Promise<void> {
	const presetPointInTime = TimeUtils.timestamp(baseline.timestamp);
	const pointInTime = await PointInTimePicker.showDialog(
		projects,
		[ETimePickerType.BASELINE, ETimePickerType.SYSTEM_VERSION, ETimePickerType.TIMESPAN],
		false,
		presetPointInTime,
		'Select time...'
	);

	// The time context used to resolve timestamps for the baseline definitions.
	const timeContext = new TimeContext(client);
	let timestamp = await timeContext.resolveToTimestamp(pointInTime);
	if (timestamp == null) {
		timestamp = Date.now();
	}
	if (pointInTime.type === EPointInTimeType.REVISION) {
		forms.setValue(document.getElementById('date-text'), new RevisionFormatter().format(pointInTime.value));
		document
			.getElementById('date-text-wrapper')
			?.setAttribute('data-tooltip', DateUtils.formatTimestamp(timestamp));
		baseline.baselineRevision = pointInTime.value.revision;
	} else {
		forms.setValue(document.getElementById('date-text'), DateUtils.formatTimestamp(timestamp));
		document.getElementById('date-text-wrapper')?.removeAttribute('data-tooltip');
		baseline.baselineRevision = '';
	}
	baseline.timestamp = timestamp;
}
