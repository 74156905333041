import { useCallback } from 'react';
import { Form } from 'semantic-ui-react';
import type { Callback } from 'ts/base/Callback';
import type { TeamscaleServiceClient } from 'ts/base/client/TeamscaleServiceClient';
import { useTeamscaleServiceClient } from 'ts/base/hooks/TeamscaleServiceClientHook';
import { StringUtils } from 'ts/commons/StringUtils';
import type { AmbiguousRevision } from 'ts/commons/time/components/AmbiguousRevisionPicker';
import { useTimePickerContext } from 'ts/commons/time/components/TimePickerContext';
import { useInputWithDefault } from 'ts/commons/time/components/TimePickerUtils';
import { EPointInTimeType } from 'ts/commons/time/EPointInTimeType';
import { ETimePickerType } from 'ts/commons/time/ETimePickerType';
import type { TypedPointInTime } from 'ts/commons/time/TypedPointInTime';
import type { CommitDescriptor as DataCommitDescriptor } from 'typedefs/CommitDescriptor';

/**
 * Extracts the commit infos if only one commit matches the revision or redirects to the Commit Selector tab if there
 * are multiple matches.
 */
function resolveWithCommits(
	commitsWithProjects: Array<{ commit: DataCommitDescriptor; project: string }>,
	revision: string,
	setTypedPointInTime: Callback<TypedPointInTime>,
	setExtraProjects: Callback<AmbiguousRevision>,
	setActiveTabKey: Callback<ETimePickerType>
): Promise<string | undefined> {
	if (commitsWithProjects.length === 0) {
		return Promise.resolve('No commits found for revision ' + revision + '.');
	} else if (commitsWithProjects.length === 1) {
		const commit = commitsWithProjects[0]!.commit;
		const timestamp = commit.timestamp;
		const branch = commit.branchName;
		if (typeof timestamp !== 'number') {
			return Promise.resolve("Commit doesn't contain expected timestamp.");
		}
		setTypedPointInTime({ type: EPointInTimeType.REVISION, value: { revision, timestamp, branch } });
		return Promise.resolve(undefined);
	} else {
		setExtraProjects({ revision, commitsWithProjects });
		setActiveTabKey(ETimePickerType.AMBIGUOUS_REVISION);
		return Promise.resolve(
			'There are several matches for revision ' +
				revision +
				' in different repositories. Please select the appropriate repository on the ' +
				ETimePickerType.AMBIGUOUS_REVISION +
				' tab.'
		);
	}
}

/** Resolves the revision in all projects and returns an error message if any error appears. */
async function resolveRevisionInAllProjects(
	revision: string,
	setTypedPointInTime: Callback<TypedPointInTime>,
	client: TeamscaleServiceClient,
	projects: string[] | null,
	setAmbiguousRevision: Callback<AmbiguousRevision>,
	setActiveTabKey: Callback<ETimePickerType>
): Promise<string | undefined> {
	revision = revision.trim();
	if (StringUtils.isEmptyOrWhitespace(revision)) {
		return Promise.resolve('Value of Revision is required!');
	}
	if (projects == null) {
		return Promise.resolve(
			'No projects are available for the selector. It probably means that this component was not rendered properly.'
		);
	}
	const receivedCommits = await Promise.all(
		projects.map(project =>
			client.getCommitsForRevision(project, revision).then(commits =>
				commits.map(commit => {
					return { commit, project };
				})
			)
		)
	).then(commitsByProjects => commitsByProjects.flat());
	return resolveWithCommits(receivedCommits, revision, setTypedPointInTime, setAmbiguousRevision, setActiveTabKey);
}

function setInitialValue(defaultValue: TypedPointInTime | null): string | undefined {
	if (defaultValue != null && defaultValue.type === EPointInTimeType.REVISION) {
		return defaultValue.value.revision;
	}
	return '';
}

/**
 * A component for picking a revision. If the revision is ambiguous, redirects the user to the
 * {@link AmbiguousRevisionPicker} tab.
 */
export function RevisionPicker(): JSX.Element | null {
	const { setAmbiguousRevision, setActiveTabKey, projects } = useTimePickerContext();
	const client = useTeamscaleServiceClient();
	const validateAndExtract = useCallback(
		(value, setTypedPointInTime) =>
			resolveRevisionInAllProjects(
				value,
				setTypedPointInTime,
				client,
				projects,
				setAmbiguousRevision,
				setActiveTabKey
			),
		[client, projects, setActiveTabKey, setAmbiguousRevision]
	);
	const { selectedValue, setSelectedValue } = useInputWithDefault('revision', validateAndExtract, setInitialValue);

	if (projects == null) {
		return null;
	}
	return (
		<Form.Input
			data-testid="revision-input"
			value={selectedValue}
			onChange={(event, { value }) => setSelectedValue(value)}
		/>
	);
}
